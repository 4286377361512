@tailwind base;
@tailwind components;
@tailwind utilities;

.dragged-item {
    opacity: 0.8 !important; 
    cursor: grab !important;
}

/* CSS */
.body-no-scroll {
    overflow: hidden;
  }
  
  .body-scroll {
    overflow-y: auto;
  }

  .containerWidthWithSidebar {
    width: calc(100% - 265px);
  }
  
  @media (max-width: 1200px) {
    .containerWidthWithSidebar {
      width: 100%;
    }
  }